import { HttpService } from "@/shared/http.service.shared";

export function accountService() {
    const login = async(formData: any) => {
        const urlPath = '/user/login';
        const login = await HttpService.postReq(formData, urlPath);
        if(login) {
            console.log('Save data to store');
        }
      }
    
    // User signup
    const signup = async(formData: any) => {
    const urlPath = '/user/register';
    const signup = await HttpService.postReq(formData, urlPath);
    if(signup) {
        console.log('Save data to store');
    }
    }

    const forgotPassword = async(formData: any) => {
        const urlPath = '/user/forgotpassword';
        const forgotPassword = await HttpService.putReq(formData, urlPath);
        if(forgotPassword) {
            console.log('Save data to store');
        }
    }

    // Reset password
    const resetPassword = async(passwordResetToken: string, formData: any) => {
    const urlPath = `/user/forgotpassword${passwordResetToken}`;
    const resetPassword = await HttpService.putReq(formData, urlPath);
        if(resetPassword) {
            console.log('Save data to store');
        }
    }

    // Confirm email
    const confirmEmail = async(accountActivationToken: string) => {
        const urlPath = `/user/forgotpassword${accountActivationToken}`;
    const confirmEmail = await HttpService.putReq('', urlPath);
        if(confirmEmail) {
            console.log('Save data to store');
        }
    }

    return {
        login,
        signup,
        forgotPassword,
        resetPassword,
        confirmEmail
    }
}

