<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
          <ion-title class="ion-text-center">Ionic Vue Chat</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
          <ion-grid>
      <ion-row class="ion-justify-content-center">
        <ion-col size-xl="4" size-lg="5" size-md="8" size-xs="12">
            <ion-card>
                <ion-card-content>
            <form method="post" @submit.prevent="login">
                <ion-list>
                    <ion-item>
                        <ion-label position="floating">Username or email</ion-label>
                        <ion-input type="text" required v-model="username" autocapitalize="none"></ion-input>
                    </ion-item>
                        <span class="text-danger">{{loginError['username']}}</span>
                    <ion-item>
                        <ion-label position="floating">Password</ion-label>
                        <ion-input type="password" v-model="password"> </ion-input>
                    </ion-item>
                        <span class="text-danger">{{loginError['password']}}</span>
                    <div class="ion-padding">
                        <ion-button type="submit" expand="block" size="size">Login</ion-button>
                    </div>
                </ion-list>
            </form>
            <ion-row>
                <ion-col size-xl="" size-lg="6" size-md="9" size-xs="12" class="mouse-pointer">
                    <ion-label class="primary-color" (click)="signupModal()">
                        Signup
                    </ion-label> | 
                    <ion-label class="primary-color" (click)="forgotPasswordModal()">
                        Forgot password?
                    </ion-label>
                </ion-col>
              </ion-row>
            </ion-card-content>
        </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { 
  IonPage, 
  IonHeader, 
  IonToolbar,
  IonTitle, 
  IonContent, 
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  } from '@ionic/vue';
import { accountService } from './account.service';


export default  {
  name: 'Home',
  mounted: () => {
    console.log('Before component is created');
  },
  created: () => {
    console.log('After component is created.')
  },
  methods: {
    ionViewDidEnter() {
      console.log('Home page did enter');
    },
    ionViewDidLeave() {
      console.log('Home page did leave');
    },
    ionViewWillEnter() {
      console.log('Home page will enter');
    },
    ionViewWillLeave() {
      console.log('Home page will leave');
    }
  },
  components: { 
    IonPage,
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent, 
    IonGrid, 
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton
     },
  setup() {
    const { login } = accountService();
      console.log('Logiin');
  }
}
</script>