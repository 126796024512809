import axios from 'axios';
import { Config } from '@/shared/config.shared'

export const HttpService = {
    async getReq(urlPath: string): Promise<any> {
        const authKey = 'fromstore';
        if(authKey) {
            this.authHeader(authKey).get(urlPath);
        } else {
            axios.get(urlPath);
        }
    },

    async postReq(urlPath: string, formData: any): Promise<any>{
        const authKey = 'fromstore';
        if(authKey) {
            this.authHeader(authKey).post(urlPath, {body: formData});
        } else {
            axios.post(urlPath, {body: formData});
        }
    },

    async putReq(urlPath: string, formData: any): Promise<any> {
        const authKey = 'fromstore';
        if(authKey) {
            this.authHeader(authKey).put(urlPath, {body: formData});
        } else {
            axios.put(urlPath, {body: formData});
        }
    },

    async deleteReq(urlPath: string): Promise<any> {
        const authKey = 'fromstore';
        if(authKey) {
            this.authHeader(authKey).delete(urlPath);
        } else {
            axios.delete(urlPath);
        }
    },

    authHeader(token: any) {
        return axios.create({
            baseURL: Config.apiUrl,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
}