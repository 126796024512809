
import { 
  IonPage, 
  IonHeader, 
  IonToolbar,
  IonTitle, 
  IonContent, 
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  } from '@ionic/vue';
import { accountService } from './account.service';


export default  {
  name: 'Home',
  mounted: () => {
    console.log('Before component is created');
  },
  created: () => {
    console.log('After component is created.')
  },
  methods: {
    ionViewDidEnter() {
      console.log('Home page did enter');
    },
    ionViewDidLeave() {
      console.log('Home page did leave');
    },
    ionViewWillEnter() {
      console.log('Home page will enter');
    },
    ionViewWillLeave() {
      console.log('Home page will leave');
    }
  },
  components: { 
    IonPage,
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent, 
    IonGrid, 
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton
     },
  setup() {
    const { login } = accountService();
      console.log('Logiin');
  }
}
